import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SetupService } from 'src/app/services/setup.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/model/user.interface';
import { NgIf, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [NgIf, TranslateModule, NgClass],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

  setupService = inject(SetupService);

  constructor(
    private userService: UserService
  ) { }

  get user(): User {
    return this.userService.user;
  }

}
